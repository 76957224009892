import React from 'react';
import './InstructorProfile.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Mentor } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { Image } from 'react-bootstrap';
import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import MentorSlider from 'components/_v2/mentorSlider/MentorSlider';

const MentorDetailsRenderer = ({ mentor }: { mentor: Mentor }) => {
	return (
		<div className="d-flex flex-column ms-2 justify-content-center gap-3">
			<div className="d-flex text-font-sizing gap-1">
				<Image src={toAbsoluteUrl('/media/instructor-images/instructor_rating.svg')} alt="rating" />
				{/* {mentor.userProfile?.[0]?.gender} */}
				4.7 Instructor Rating
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_reviews.svg')}
					alt="rating"
				/>
				67,254 Reviews
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_students.svg')}
					alt="rating"
				/>
				2,00,000 Students
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_courses.svg')}
					alt="rating"
				/>
				7 Courses
			</div>
		</div>
	);
};

const InstructorProfile = ({
	instructors,
	fullWidth = false,
}: {
	instructors: { mentor: Mentor }[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	const getMentorInitials = (name) => {
		if (!name) return '';
		const words = name.trim().split(' ');
		return words.length > 1
			? `${words[0][0]}${words[1][0]}`.toUpperCase()
			: words[0][0].toUpperCase();
	};
	return (
		<section className={fullWidth ? '' : isSmallScreen ? 'pt-3' : 'px-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'offset-lg-1 p-lg-0'}>
								<div className="">
									<h2 className="instructor-heading ibm_semi">Meet Your Mentor</h2>
									<div className="my-4">
										<MentorSlider instructors={instructors} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default InstructorProfile;
