import { getUserByToken } from 'pages/auth/core/_requests';
import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useMemo,
	useEffect,
	useCallback,
	useRef,
} from 'react';
import { UserType } from 'types/globalTypes';
import * as authHelper from 'pages/auth/core/AuthHelpers';
import { toast } from 'react-hot-toast';
import {
	AUTH_LOCAL_STORAGE_KEY,
	PRE_RECORDED_COURSE_KEY,
	VERIFY_USER_STORAGE_KEY,
} from 'pages/auth/core/AuthHelpers';
import { useUserAddressContext } from './UserAddressContext';

interface UserContextValue {
	user: UserType | null;
	setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
	logOutUser: () => void;
	localStorageAuthToken: string;
	refetchLocalStorageTokenDetails: () => void;
	loadingUser: boolean;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

interface UserProviderProps {
	children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<UserType | null>(null);
	const initialFetchDone = useRef(false);
	const [localStorageAuthToken, setLocalStorageAuthToken] = useState(
		authHelper.getAuth()?.access_token,
	);
	const [loadingUser, setLoadingUser] = useState(true);

	const logOutUser = useCallback(() => {
		setUser(null);
		authHelper.removeAuth();
		localStorage.removeItem(PRE_RECORDED_COURSE_KEY);
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
		localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
		setLocalStorageAuthToken('');
	}, []);
	const fetchUser = async () => {
		try {
			setLoadingUser(true);
			setUser(null);

			const token = authHelper.getAuth()?.access_token;
			if (!token) {
				console.warn('No token found, user fetch aborted.');
				setLoadingUser(false);
				return;
			}

			const { data } = await getUserByToken(token);
			const newUser = data?.data?.getMe;

			if (newUser) {
				setUser({ ...newUser });
			}

			localStorage.setItem(
				AUTH_LOCAL_STORAGE_KEY,
				JSON.stringify({
					access_token: newUser ? token : '',
					user: newUser,
				}),
			);

			setLoadingUser(false);
		} catch (error) {
			setLoadingUser(false);
			console.error('Error fetching user data:', error);
			toast.error('Failed fetching user details. Please try again.');
		}
	};

	const refetchLocalStorageTokenDetails = async () => {
		const newToken = authHelper.getAuth()?.access_token;

		if (newToken) {
			await setLocalStorageAuthToken(newToken);
			await fetchUser();
		}
	};

	useEffect(() => {
		if (localStorageAuthToken && !initialFetchDone.current) {
			fetchUser();
			initialFetchDone.current = true;
		} else {
			setLoadingUser(false);
		}
	}, [localStorageAuthToken]);

	const value = useMemo(() => {
		return {
			user,
			setUser,
			localStorageAuthToken: localStorageAuthToken ?? '',
			logOutUser,
			refetchLocalStorageTokenDetails,
			loadingUser,
		};
	}, [user, setUser, localStorageAuthToken, logOutUser, loadingUser]);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextValue => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};
