import Carousel from 'react-bootstrap/Carousel';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import { useState } from 'react';

const MentorSlider = ({ instructors }) => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	const getMentorInitials = (name) => {
		if (!name) return '';
		const words = name.trim().split(' ');
		return words.length > 1
			? `${words[0][0]}${words[1][0]}`.toUpperCase()
			: words[0][0].toUpperCase();
	};

	return (
		<div className="mentor-slider-container position-relative">
			<Carousel activeIndex={index} onSelect={handleSelect} interval={3000} controls={false}>
				{instructors.map((instructor, idx) => {
					const mentorDetails = instructor.mentor;
					const mentorFullName = mentorDetails.fullName;
					const initials = getMentorInitials(mentorFullName);

					return (
						<Carousel.Item key={mentorDetails.id}>
							<div className="mentorContainer bg-white p-4">
								<div className="d-flex align-items-center mt-4 gap-4">
									{/* Left Side: Profile Image */}
									<div>
										{mentorDetails.userProfile?.[0]?.imageUrl ? (
											<img
												src={toAbsoluteUrl(
													mentorDetails.userProfile?.[0]?.imageUrl ??
														'/media/instructor-images/instructor_profile.png',
												)}
												alt="Instructor"
												className="instructor-profile-custom-style"
											/>
										) : (
											<div className="initials-placeholder">{initials}</div>
										)}
									</div>
									{/* Mentor Details */}
									<div className="d-flex flex-column mentor-details">
										<span className="g_mid fs-18">
											<u>{mentorFullName}</u>
										</span>
										<span className="g_reg fs-15 mt-2">
											{mentorDetails.userProfile?.[0]?.qualification}
										</span>
										<p className="instructor-full-desc g_reg mt-4 text-justify">
											{mentorDetails.userProfile?.[0]?.description}
										</p>
									</div>
								</div>
							</div>
						</Carousel.Item>
					);
				})}
			</Carousel>

			{/* Custom Next and Prev Buttons Positioned at Container Borders */}
			{instructors.length > 1 && (
				<>
					{index > 0 && (
						<button
							className="prev-btn position-absolute border-0 bg-none"
							style={{ left: '-20px', top: '50%', transform: 'translateY(-50%)' }}
							onClick={() => setIndex(index - 1)}>
							<Image src={toAbsoluteUrl('/media/icons/common-slider-prev.svg')} alt="Left_Slider" />
						</button>
					)}
					<button
						className="next-btn position-absolute border-0 bg-none"
						style={{ right: '-20px', top: '50%', transform: 'translateY(-50%)' }}
						onClick={() => setIndex((index + 1) % instructors.length)}>
						<Image src={toAbsoluteUrl('/media/icons/common-slider-next.svg')} alt="Right_Slider" />
					</button>
				</>
			)}
		</div>
	);
};

export default MentorSlider;
