import React, { useEffect, useMemo, useState } from 'react';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import HeroSection from 'components/_v2/HeroSection/HeroSection';
import Partners from 'components/_v2/Partners/Partners';
import Best from 'components/_v2/BeTheBest/Best';
import CourseCta from 'components/_v2/CTA/CourseCta';
import Discover from 'components/_v2/Discover/Discover';
import Stories from 'components/_v2/Stories/Stories';
import Faq from 'components/_v2/HgFAQ/Faq';
import DownloadApp from 'components/_v2/DownloadApp/DownloadApp';
import Blog from 'components/_v2/Blog/Blog';
import Popup from 'components/_v2/popup/Popup';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import { useUserContext } from 'context/UserContext';
import { COURSE_URL, MY_COURSE_URL } from 'pages/routing/routePages';
import { SelectedTabType, SliderComponentType } from 'components/_v2/CommonComponents/types';
import {
	EXPLORE_OFFLINE_SECTION,
	EXPLORE_ONLINE_SECTION,
	EXPLORE_RECORDED_SECTION,
} from 'utils/consts';
import { reShowChatStickyOnUi } from 'utils/helpers';
import { CourseTypeEnum } from 'components/_v2/Course/type';

export const HomePage = () => {
	const { userCourses } = useCourseContext();
	const { courses, getCourses } = useCourses();
	const { user } = useUserContext();
	const [showPopup, setShowPopup] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		getCourses();
	}, []);
	useEffect(() => {
		const targetId = location.hash.replace('#', '');
		if (!targetId) return;

		const observer = new MutationObserver(() => {
			const targetElement = document.getElementById(targetId);

			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
				observer.disconnect();
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, [location]);

	useEffect(() => {
		const cookiesAccepted = localStorage.getItem('cookiesAccepted');
		if (!cookiesAccepted) {
			setShowPopup(true);
		}
	}, []);

	const handleAcceptCookies = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		setShowPopup(false);
	};

	const upcomingOfflineBatches = useMemo(
		() => courses.filter((course) => course.courseMode && course.courseMode.name === 'Offline'),
		[courses],
	);

	const upcomingOnlineBatches = useMemo(
		() => courses.filter((course) => course.courseMode && course.courseMode.name === 'Online'),
		[courses],
	);

	const recordedBatches = useMemo(
		() =>
			courses.filter(
				(course) => !course.courseMode && course.courseType.slug === CourseTypeEnum.RECORDED_COURSE,
			),
		[courses],
	);

	useEffect(() => {
		reShowChatStickyOnUi();
	}, []);

	return (
		<div>
			<HeroSection />
			{/*<OurCourse courses={courses} error={error} loading={loading} />*/}
			{user && userCourses.length > 0 && (
				<div className="py-3">
					<CommonSlider
						headingText="Your Courses"
						headingSubText="Continue Learning from HoGrowth Mentors"
						courses={userCourses}
						viewAllAction={() =>
							navigate({ pathname: MY_COURSE_URL, search: `?courseMode=${SelectedTabType.ALL}` })
						}
						userCourses={userCourses}
						user={user}
						showBackdropShadow={false}
						slidesToShow={4}
						sliderComponentType={SliderComponentType.COURSES}
						showViewAllButton
					/>
				</div>
			)}
			{upcomingOfflineBatches.length > 0 && (
				<div className="py-3" id={EXPLORE_OFFLINE_SECTION}>
					<CommonSlider
						headingText="Upcoming Offline Batches"
						headingSubText="Learn from the Experienced Pro Traders"
						courses={upcomingOfflineBatches}
						viewAllAction={() =>
							navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.OFFLINE}` })
						}
						userCourses={userCourses}
						user={user}
						showBackdropShadow={false}
						slidesToShow={4}
						sliderComponentType={SliderComponentType.COURSES}
						showViewAllButton
					/>
				</div>
			)}
			{/* <Best wrapperClass="mt-3" /> */}
			{upcomingOnlineBatches.length > 0 && (
				<div className="py-3" id={EXPLORE_ONLINE_SECTION}>
					<CommonSlider
						headingText="Upcoming Online Batches"
						headingSubText="Learn from the Experienced Pro Traders"
						courses={upcomingOnlineBatches}
						viewAllAction={() =>
							navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.ONLINE}` })
						}
						userCourses={userCourses}
						user={user}
						showBackdropShadow={false}
						slidesToShow={4}
						sliderComponentType={SliderComponentType.COURSES}
						showViewAllButton
					/>
				</div>
			)}
			<Best wrapperClass="mt-3" />
			{recordedBatches.length > 0 && (
				<div className="py-3" id={EXPLORE_RECORDED_SECTION}>
					<CommonSlider
						headingText="Recorded Batches"
						headingSubText="Learn from the Experienced Pro Traders"
						courses={recordedBatches}
						viewAllAction={() =>
							navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.RECORDED}` })
						}
						userCourses={userCourses}
						user={user}
						showBackdropShadow={false}
						slidesToShow={4}
						sliderComponentType={SliderComponentType.COURSES}
						showViewAllButton
					/>
				</div>
			)}
			<div className="bg-white">
				<Discover />
				<Blog />
			</div>
			{/* <HowItWork /> */}
			<CourseCta />
			<Partners smColClass="col-12" mdColClass="col-md-10" lgColClass="col-lg-10" />
			<Stories />
			<Faq />
			<DownloadApp />
			{showPopup && <Popup onClose={handleAcceptCookies} />}
		</div>
	);
};
