import Select from 'react-select';
import { CustomDropdownIndicator } from 'components/_v2/CommonComponents/CommonSelect';
import React from 'react';
import { useUserAddressContext } from 'context/UserAddressContext';

const StateAndCountryDropDowns = ({
	handleCountryChange,
	handleStateChange,
}: {
	handleCountryChange: (country: string) => void;
	handleStateChange: (state: string) => void;
}) => {
	const { countries, states, selectedCountryId, selectedStateId, changeCountry, changeState } =
		useUserAddressContext();

	const handleCountrySelection = (newCountry: { label: string; value: string }) => {
		handleCountryChange(newCountry.value);
		changeCountry(newCountry);
	};

	const handleStateSelection = (newState: { label: string; value: string }) => {
		handleStateChange(newState.value);
		changeState(newState);
	};

	return (
		<div className="row">
			<div className="col-12 col-sm-6">
				<label htmlFor="country" className="profile-labels g_mid fs-16">
					Country
				</label>
				<span className="text-danger">*</span>
				<br />
				<Select
					id="country"
					name="country"
					options={countries}
					value={countries.find((country) => country.value === selectedCountryId)}
					onChange={handleCountrySelection}
					placeholder={`Select Country`}
					classNamePrefix="react-select"
					styles={{
						control: (provided) => ({
							...provided,
							lineHeight: '35px',
						}),
					}}
					isSearchable={true}
					components={{
						DropdownIndicator: CustomDropdownIndicator,
					}}
				/>
			</div>
			<div className="col-12 col-sm-6">
				<label htmlFor="state" className="profile-labels g_mid fs-16">
					State
				</label>
				<span className="text-danger">*</span>
				<br />
				<Select
					id="state"
					name="state"
					options={states}
					value={states?.find((state) => state.value === selectedStateId) || null} // ✅ Prevents auto-fill of old state
					onChange={handleStateSelection}
					placeholder="Select State"
					classNamePrefix="react-select"
					isSearchable={true}
					styles={{
						control: (provided) => ({
							...provided,
							lineHeight: '35px',
						}),
					}}
					components={{
						DropdownIndicator: CustomDropdownIndicator,
					}}
				/>
			</div>
		</div>
	);
};

export default StateAndCountryDropDowns;
