import React, { useMemo } from 'react';
import './DetailHeroSection.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course, CourseTypeEnum } from 'components/_v2/Course/type';
import StarRating from './StarRatings';
import moment from 'moment';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { Badge, Image } from 'react-bootstrap';

const starRating = 4.5;

const DetailHeroSection = ({ course }: { course: Course }) => {
	const isSmallScreen = useIsSmallScreen(992);

	const topHeaderDetails = useMemo(
		() => (
			<div className="d-flex justify-content-start justify-content-xs-center flex-xs-nowrap flex-sm-wrap flex-xl-nowrap w-sm-0 w-100 px-sm-0 w-100 mb-2 flex-wrap gap-2 px-2">
				<div className="d-flex flex-sm-nowrap flex-row flex-wrap gap-2">
					<Badge pill className="best-seller-badge">
						<div className="d-flex align-items-center gap-1">
							<Image
								src={toAbsoluteUrl('/media/course-detail-images/best-seller.svg')}
								alt="Best Seller"
							/>
							<span className="p_semi" style={{ letterSpacing: '1px' }}>
								{course.courseType.slug != CourseTypeEnum.WEBINAR
									? '99% Seats Filled'
									: 'Limited Seats'}
							</span>
						</div>
					</Badge>
					{/* 
					{course.courseType.slug != CourseTypeEnum.WEBINAR && (
						<div className="d-flex align-items-center justify-content-center gap-1">
							<span className="goldColor">{starRating}</span>
							<StarRating rating={starRating} removeSpacingFromBottom />
							<span className="p_mid fs-16 primaryColor">({course.ratingCounts})</span>
						</div>
					)} */}
				</div>
				<span className="fs-16 pe-1 d-flex align-items-center justify-content-start w-sm-0 gap-1">
					<span>1000+ students</span>
					<Badge pill className="course-mode-badge fs-16 p_semi" style={{ letterSpacing: '1px' }}>
						{course?.courseMode ? `${course?.courseMode?.name} Batch` : course.courseType.name}
					</Badge>
				</span>
			</div>
		),
		[course],
	);

	const mentorRenderer = useMemo(
		() => (
			<div className="d-flex">
				<div className="d-flex px-sm-0 flex-wrap px-2">
					Delivered By -
					{course.mentors.map((mentor, index) => {
						const mentorDetails = mentor.mentor;
						const fullName = mentorDetails?.fullName;
						return (
							<span className="goldColor" key={mentor.mentor.id}>
								&nbsp;{fullName}
								{mentorDetails.userProfile?.[0]?.qualification && (
									<span>, {mentorDetails.userProfile?.[0]?.qualification}</span>
								)}
								&nbsp;
								{index < course.mentors.length - 1 && '|'}
							</span>
						);
					})}
				</div>
			</div>
		),
		[course],
	);

	return (
		<>
			{!isSmallScreen && (
				<section
					style={{
						backgroundImage: toAbsoluteUrl('/media/background/detail-bg.png'),
						backgroundColor: '#1a1a1a',
						color: 'white',
						marginTop: '88px',
					}}
					className="px-3">
					<div className="h-100 container px-0 py-5">
						<div className="row h-100 align-items-center pe-2 pe-lg-5 pe-sm-3">
							<div className="col-lg-7 courseDetailsMediaQuery align-content-center offset-1 px-md-4 px-lg-3 px-xxl-0">
								{topHeaderDetails}
								<h1 className="detail-heading white ibm_semi">{course.name}</h1>
								<p className="detail-label p_mid">{course.shortDescription}</p>
								{mentorRenderer}
							</div>
						</div>
					</div>
				</section>
			)}

			{isSmallScreen && (
				<div className="drawer-align spacingHeaderTop">
					{course?.previewVideo && (
						<video className="w-100 align-self-center" controls controlsList="nodownload">
							<source src={course.previewVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					)}
					<div className="p-lg-5 p-sm-3 mx-sm-3 mx-md-4 mx-2 p-2">
						{topHeaderDetails}
						<h1 className="fs-20 black g_mid fw-bold px-sm-0 px-2 pb-2">{course.name}</h1>
						<p className="fs-16 g_reg fw-normal px-sm-0 px-2">{course.shortDescription}</p>
						{mentorRenderer}
						<div className="card-body pt-4">
							{course?.courseSettings?.dateVisible &&
								course?.startDatetime &&
								course?.endDatetime && (
									<div className="d-flex align-items-center card-inner-layout">
										<div>
											<img
												src={toAbsoluteUrl('/media/detail-hero-section/cdate.svg')}
												alt="Date"
												className="between-space"
											/>
										</div>
										<div>
											<span className="card-sticky-content g_reg">
												{moment.parseZone(course.startDatetime).format("DD MMM 'YY")} -{' '}
												{moment.parseZone(course.endDatetime).format("DD MMM 'YY")}
											</span>
										</div>
									</div>
								)}
							{course?.courseSettings?.timeVisible &&
								course?.startDatetime &&
								course?.endDatetime && (
									<div className="d-flex align-items-center card-inner-layout">
										<div>
											<img
												src={toAbsoluteUrl('/media/detail-hero-section/ctime.svg')}
												alt="Time"
												className="between-space"
											/>
										</div>
										<div>
											<span className="card-sticky-content g_reg">
												{moment(course.startDatetime).format('h:mm A')} -{' '}
												{moment(course.endDatetime).format('h:mm A')}
											</span>
										</div>
									</div>
								)}

							{course?.courseSettings?.languageVisible && course?.courseLanguages.length ? (
								<div className="d-flex align-items-center card-inner-layout">
									<div>
										<img
											src={toAbsoluteUrl('/media/detail-hero-section/clanguage.svg')}
											alt="Language"
											className="between-space"
										/>
									</div>
									<div>
										<span className="card-sticky-content g_reg">
											{course.courseLanguages.map((language, index) => (
												<span key={index}>
													{language.name}
													{index < course.courseLanguages.length - 1 && ', '}
												</span>
											))}
										</span>
									</div>
								</div>
							) : null}
							{course?.courseSettings?.levelVisible && course?.courseLevel?.name && (
								<div className="d-flex align-items-center card-inner-layout">
									<div>
										<img
											src={toAbsoluteUrl('/media/detail-hero-section/clevel.svg')}
											alt="Level"
											className="between-space"
										/>
									</div>
									<div>
										<span className="card-sticky-content g_reg">{course.courseLevel.name}</span>
									</div>
								</div>
							)}
							{course.hasCertificate && (
								<div className="d-flex align-items-center card-inner-layout">
									<Image
										src={toAbsoluteUrl('/media/detail-hero-section/certificate.svg')}
										alt="certificate"
										className="between-space"
									/>
									<span className="card-sticky-content g_reg">Certificate of Completion</span>
								</div>
							)}

							{course.courseType.slug !== CourseTypeEnum.RECORDED_COURSE && (
								<div className="d-flex align-items-center card-inner-layout">
									<div>
										<img
											src={toAbsoluteUrl(
												'/media/detail-hero-section/' +
													(course?.courseMode?.name === 'Online' ||
													course?.courseType?.slug === CourseTypeEnum.WEBINAR
														? 'zoom'
														: 'clocation') +
													'.png',
											)}
											alt="Location"
											className="between-space"
										/>
									</div>

									<div>
										<span className="card-sticky-content g_reg">
											{course?.courseMode?.name === 'Online' || course?.courseType?.slug
												? 'Zoom'
												: course?.address}
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DetailHeroSection;
