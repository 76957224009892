import { Mentor } from 'components/_v2/Course/type';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';
import Stars from 'components/_v2/CommonComponents/SubmitStarRatings/Stars';

export const mentorNamesRenderer = (mentors: { mentor: Mentor }[]) => {
	let combinedText = mentors
		.map((mentorDetails) => mentorDetails.mentor.fullName)
		.join(", ");

	return combinedText.length > 30 ? combinedText.substring(0, 30) + "..." : combinedText;
};

export const mentorProfileNameRenderer = (
	mentors?: { mentor: Mentor }[],
	isSmallScreen = false,
) => {
	if (!mentors) {
		return;
	}
	const getMentorInitials = (name) => {
		if (!name) return '';
		const words = name.trim().split(' ');
		return words.length > 1
			? `${words[0][0]}${words[1][0]}`.toUpperCase()
			: words[0][0].toUpperCase();
	};

	return mentors.slice(0, 2).map((mentor, index) => (
		<div
			key={mentor.mentor.id + index}
			className={isSmallScreen ? 'd-flex d-md-none w-100' : 'd-none d-sm-flex h-100'}>
			<div>
				{mentor.mentor.userProfile?.[0]?.imageUrl ? (
					<img
						src={toAbsoluteUrl(mentor.mentor.userProfile?.[0]?.imageUrl)}
						style={{ borderRadius: '50%' }}
						className="me-2"
						alt="Mentor "
						height="48px"
						width="48px"
					/>
				) : (
					<div className="initials-placeholder-helper">
						{getMentorInitials(mentor.mentor.fullName)}
					</div>
				)}
			</div>
			<div className="">
				<span className="profile-mentor">Mentor</span> <br />
				<span className="profile-mentro-name g_mid">{mentor.mentor.fullName}</span>
			</div>
		</div>
	));
};

export const reviewCardRenderer = ({
	key,
	name,
	ratings,
	desc,
	isOnlySingleTestimonial,
}: {
	key: string | number;
	name: string;
	ratings: number;
	desc: string;
	isOnlySingleTestimonial: boolean;
}) => {
	return (
		<div className={`${isOnlySingleTestimonial ? 'col-xxl-12' : 'col-xxl-6'} col-lg-12`} key={key}>
			<div className="card blackBorder">
				<div className="card-body">
					<div className="testimonials-icon d-flex align-items-center">
						<div>
							<div className="testimonial-profile-name g_mid">{name}</div>
							<div className="d-flex">
								{Array.from(Array(5)).map((_, index) => (
									<Stars
										key={index}
										index={index}
										onClickHandler={() => null}
										onMouseEnter={() => null}
										onMouseLeave={() => null}
										ratings={ratings}
									/>
								))}
							</div>
						</div>
					</div>
					<div className="testimonials-main-desc g_reg">{desc}</div>
				</div>
			</div>
		</div>
	);
};
