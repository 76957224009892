import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/sass/css/profile.css';
import moment from 'moment';
// import { Verification } from "../../../pages/auth/Verification";
import { ChangePassword } from '../../common/ChangePassword/ChangePassword';
import { FaPencilAlt, FaRegEdit, FaUserEdit } from 'react-icons/fa';
import { SENDEMAILVERIFICATION } from '../../Courses-Page/core/requests';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

export const PersonalDetails = (props: { profile: any; profilerefetch: any }) => {
	const { profile, profilerefetch } = props;
	const [openVerification, setOpenVerification] = useState(false);
	const [emailverification] = useMutation(SENDEMAILVERIFICATION);
	const [open, setOpen] = useState(false);
	const [openRegister, setOpenRegister] = useState(false);
	const [changePassword, setchangePasswordOpen] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const navigate = useNavigate();
	const handleChangePasswordOpen = () => {
		setchangePasswordOpen(true);
	};
	const Myinterests = () => {
		navigate('/myinterests');
	};
	const editprofile = () => {
		navigate('/editpersonaldetails');
	};
	const handleVerificationClose = () => setOpenVerification(false);
	const handleOpenVerificationOpen = () => {
		setOpenVerification(true);
	};
	const sendemailverification = async () => {
		await emailverification({
			onCompleted: () => {
				toast.success('Email verification link send successfully in your register mail id');
			},
			onError: (err: any) => {
				toast.error(err.message);
			},
		});
	};
	const toggleLoginOpen = () => {
		setOpen(true);
		setOpenRegister(false);
	};
	const toggleRegisterOpen = () => {
		setOpenRegister(true);
		setOpen(false);
	};
	const handleRegisterClose1 = () => {
		setOpenRegister(false);
	};
	const handleForgotPasswordOpen = () => {
		setShowForgotPasswordModal(true);
	};
	const handleForgotPasswordClose = () => {
		setShowForgotPasswordModal(false);
	};
	const userAddress = profile?.getMyProfile?.userAddress[0];
	const handleEditAddress = () => {
		navigate('/editpersonaldetails');
	};
	return (
		<>
			<section>
				<div className="d-none d-md-block d-lg-block container">
					<div className="row mt-5">
						<div className="col-12">
							<div className="d-flex justify-content-between">
								<h3 className="font-title fw-bold fs-4">Personal Information </h3>
								<div className="">
									<li className="list-inline-item fs-4 link-dark  mb-0 ">
										<div className="link-dark ">
											<button
												className="font-subtitle btn btn-outline-primary fw-bold"
												onClick={editprofile}
												style={{ border: 'none' }}>
												Edit Profile
											</button>
										</div>
									</li>
									<li className="list-inline-item fs-4 link-dark mb-0">
										<div className="link-primary">
											<button
												className="font-subtitle btn btn-outline-primary fw-bold"
												style={{ border: 'none' }}
												onClick={handleForgotPasswordOpen}>
												{' '}
												Change Password
											</button>
										</div>
									</li>
								</div>
							</div>
							<div className="">
								<div className="profile-box rounded-circle text-center">
									<div className="avatar-img h-100">
										<img
											src={
												profile?.getMyProfile?.userProfile[0]?.imageUrl != null &&
												profile?.getMyProfile?.userProfile[0]?.imageUrl != ''
													? profile?.getMyProfile?.userProfile[0]?.imageUrl
													: '../media/mentor/profile_image_personaldetail.jpg'
											}
											alt=""
											className="img-fluid h-100"
										/>
									</div>
								</div>
								<form className="w-50 mt-3">
									<div className="mb-3">
										<label
											htmlFor="exampleInputEmail1"
											className="font-subtitle form-label text-muted mt-4">
											Full Name
										</label>
										<p className="font-subtitle fw-bold">
											{profile?.getMyProfile?.firstName + ' ' + profile?.getMyProfile?.lastName}
										</p>
									</div>
									<div className="mb-3">
										<label htmlFor="number" className="font-subtitle form-label text-muted">
											Phone
											<div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer mx-2">
												{profile?.getMyProfile?.userProfile[0]?.mobileNo &&
													(profile?.getMyProfile?.isMobileNumberVerified ? (
														<>
															<span className="me-1 color6">
																<i className="fa-solid fa-circle-check"></i>
															</span>
															<span className="text-dark fw-medium">Verified</span>
														</>
													) : (
														<>
															<a
																className="text-decoration-none"
																onClick={handleOpenVerificationOpen}>
																Verify
															</a>
														</>
													))}
											</div>
										</label>
										<p className="fw-bold">{profile?.getMyProfile?.userProfile[0]?.mobileNo}</p>
									</div>
									<div className="mb-3">
										<label
											htmlFor="exampleInputEmail1"
											className="font-subtitle form-label text-muted">
											Email
											<div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer mx-3">
												{profile?.getMyProfile?.isVerified ? (
													<>
														<span className="me-2 color6">
															<i className="fa-solid fa-circle-check"></i>
														</span>
														<span className="text-dark fw-medium">Verified</span>
													</>
												) : (
													<>
														{/* <span className="me-1 red-color"><i className="fa-solid fa-circle-xmark"></i></span> */}
														<a className="text-decoration-none" onClick={sendemailverification}>
															Verify
														</a>
													</>
												)}
											</div>
										</label>
										<p className="font-subtitle fw-bold">{profile?.getMyProfile?.email}</p>
									</div>
								</form>
							</div>
							<div>
								<h3 className="font-title fw-bold head1 mt-5">Other Information</h3>
								<form className="w-100 mt-2">
									{profile?.getMyProfile?.userProfile[0]?.gender ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Gender
											</label>
											<p className="font-subtitle fw-bold">
												{profile?.getMyProfile?.userProfile[0]?.gender}
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Gender
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Gender available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}
									{profile?.getMyProfile?.userProfile[0]?.dob ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Birth Date
											</label>
											<p className="font-subtitle fw-bold">
												{moment(profile?.getMyProfile?.userProfile[0]?.dob).format('DD-MM-YYYY')}.
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Birth Date
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Birth Date available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}

									{userAddress?.address ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Address
											</label>
											<p className="font-subtitle fw-bold">
												{userAddress.address} {userAddress.state} {userAddress.city}.
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Address
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Address available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="d-block d-md-none d-lg-none container">
					<div className="row">
						<div className="col-12">
							<div className="text-start">
								<div className="text-start">
									<li className="list-inline-item fs-4 link-dark  mb-0 ">
										<div className="link-dark ">
											<button
												className="font-subtitle btn btn-outline-primary fw-bold"
												onClick={editprofile}
												style={{ border: 'none' }}>
												Edit Profile
											</button>
										</div>
									</li>
									<li className="list-inline-item fs-4 link-dark mb-0">
										<div className="link-primary">
											<button
												className="font-subtitle btn btn-outline-primary fw-bold"
												style={{ border: 'none' }}
												onClick={handleForgotPasswordOpen}>
												{' '}
												Change Password
											</button>
										</div>
									</li>
								</div>
								<div>
									<h3 className="font-title fw-bold fs-4 py-3">Personal Information </h3>
								</div>
							</div>
							<div className="">
								<div className="profile-box rounded-circle text-center">
									<div className="avatar-img h-100">
										<img
											src={
												profile?.getMyProfile?.userProfile[0]?.imageUrl != null &&
												profile?.getMyProfile?.userProfile[0]?.imageUrl != ''
													? profile?.getMyProfile?.userProfile[0]?.imageUrl
													: '../media/mentor/profile_image_personaldetail.jpg'
											}
											alt=""
											className="img-fluid h-100"
										/>
									</div>
								</div>
								<form className="w-50 mt-3">
									<div className="mb-3">
										<label
											htmlFor="exampleInputEmail1"
											className="font-subtitle form-label text-muted mt-4">
											Full Name
										</label>
										<p className="font-subtitle fw-bold">
											{profile?.getMyProfile?.firstName + ' ' + profile?.getMyProfile?.lastName}
										</p>
									</div>
									<div className="mb-3">
										<label htmlFor="number" className="font-subtitle form-label text-muted">
											Phone
											<div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer mx-2">
												{profile?.getMyProfile?.userProfile[0]?.mobileNo &&
													(profile?.getMyProfile?.isMobileNumberVerified ? (
														<>
															<span className="me-1 color6">
																<i className="fa-solid fa-circle-check"></i>
															</span>
															<span className="text-dark fw-medium">Verified</span>
														</>
													) : (
														<>
															<a
																className="text-decoration-none"
																onClick={handleOpenVerificationOpen}>
																Verify
															</a>
														</>
													))}
											</div>
										</label>
										<p className="fw-bold">{profile?.getMyProfile?.userProfile[0]?.mobileNo}</p>
									</div>
									<div className="mb-3">
										<label
											htmlFor="exampleInputEmail1"
											className="font-subtitle form-label text-muted">
											Email
											<div className="badge rounded-pill py-lg-2 bg-color3 fs-16 font-switzer mx-3">
												{profile?.getMyProfile?.isVerified ? (
													<>
														<span className="me-2 color6">
															<i className="fa-solid fa-circle-check"></i>
														</span>
														<span className="text-dark fw-medium">Verified</span>
													</>
												) : (
													<>
														{/* <span className="me-1 red-color"><i className="fa-solid fa-circle-xmark"></i></span> */}
														<a className="text-decoration-none" onClick={sendemailverification}>
															Verify
														</a>
													</>
												)}
											</div>
										</label>
										<p className="font-subtitle fw-bold">{profile?.getMyProfile?.email}</p>
									</div>
								</form>
							</div>
							<div>
								<h3 className="font-title fw-bold head1 mt-5">Other Information</h3>
								<form className="w-100 mt-2">
									{profile?.getMyProfile?.userProfile[0]?.gender ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Gender
											</label>
											<p className="font-subtitle fw-bold">
												{profile?.getMyProfile?.userProfile[0]?.gender}
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Gender
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Gender available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}
									{profile?.getMyProfile?.userProfile[0]?.dob ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Birth Date
											</label>
											<p className="font-subtitle fw-bold">
												{moment(profile?.getMyProfile?.userProfile[0]?.dob).format('DD-MM-YYYY')}.
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Birth Date
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Birth Date available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}

									{userAddress?.address ? (
										<div className="col-lg-6 mt-3">
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Address
											</label>
											<p className="font-subtitle fw-bold">
												{userAddress.address} {userAddress.state} {userAddress.city}.
											</p>
										</div>
									) : (
										<div>
											<label
												htmlFor="exampleInputEmail1"
												className="font-subtitle form-label text-muted m-1">
												Address
											</label>
											<div className="d-flex me-2">
												<p className="font-subtitle fw-bold">No Address available.</p>
												{/* <FaUserEdit style={{marginLeft: '5px'}}/> */}
												<i
													className="fa-solid fa-pen-to-square"
													style={{ marginLeft: '5px' }}
													onClick={handleEditAddress}></i>
											</div>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* {
                changePassword === true && (
                    <cha
                )
            } */}
			{/* {openVerification === true && (
        <Verification
          handleRegisterClose1={handleRegisterClose1}
          openVerification={openVerification}
          handleVerificationClose={handleVerificationClose}
          toggleLoginOpen={toggleLoginOpen}
          toggleRegisterOpen={toggleRegisterOpen}
          mobileNo={profile?.getMyProfile?.userProfile[0]?.mobileNo}
        />
      )} */}
			{showForgotPasswordModal === true && (
				<ChangePassword
					showForgotPasswordModal={showForgotPasswordModal}
					handleForgotPasswordClose={handleForgotPasswordClose}
					handleVerificationClose={handleVerificationClose}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
				/>
			)}
		</>
	);
};

export default PersonalDetails;
