import { ErrorMessage, Field, Formik } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_ORDER_AND_USER_MUTATION, ENROLL_FREE_COURSE } from 'components/Cart/core/requests';
import { RazorpayResponseObject, RegistrationUserOutput } from 'generated/types';
import { useUserAddressContext } from 'context/UserAddressContext';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import StateAndCountryDropDowns from 'components/_v2/PaymentDetails/StateAndCountryDropdowns';
import { useUserContext } from 'context/UserContext';
import Button from 'components/_v2/CommonComponents/Button';
import Loading from 'components/_v2/CommonComponents/Loading';
import { RUPEE_SYMBOL } from 'utils/consts';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import toast from 'react-hot-toast';
import { CreateOrderDetailsInput } from 'types/globalTypes';
import { Course, CourseCostType } from 'components/_v2/Course/type';
import { getUserByToken, NEW_STUDENT_REGISTER, VERIFYOTP } from 'pages/auth/core/_requests';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Form, Image } from 'react-bootstrap';
import { AUTH_LOCAL_STORAGE_KEY, VERIFY_USER_STORAGE_KEY } from 'pages/auth/core/AuthHelpers';
import { getDetailsSubUrl } from 'pages/routing/routePages';
import { useNavigate } from 'react-router-dom';
import useCourses from 'hooks/useCourses';

interface ApiResponse {
	createOrderNewUser: RazorpayResponseObject;
}

interface FormType {
	fullName: string;
	email: string;
	mobileNumber: string;
	country: string;
	state: string;
	isSubscribed: Boolean;
}

const LoginNewUser = ({ course }: { course: Course }) => {
	const [createOrderNewUser, { loading: newUserOrderDataLoading }] = useMutation<ApiResponse>(
		CREATE_ORDER_AND_USER_MUTATION,
	);

	const [newStudentRegister, { loading: newStudentRegisterLoading }] = useMutation<{
		newStudentRegister: RegistrationUserOutput;
	}>(NEW_STUDENT_REGISTER);

	const [enrollFreeCourse, { loading: loadingEnrollFreeCourse }] = useMutation(ENROLL_FREE_COURSE);

	const [verifyOtp, { loading: loadingOtp }] = useMutation<{
		newStudentverification: RegistrationUserOutput;
	}>(VERIFYOTP);

	const [isVerificationContent, setIsVerificationContent] = useState(false);
	const [timer, setTimer] = useState(89);
	const [isResendEnabled, setIsResendEnabled] = useState(false);
	const navigate = useNavigate();
	const { refetchLocalStorageTokenDetails, localStorageAuthToken, setUser } = useUserContext();
	const { getUserCourses } = useCourses();

	useEffect(() => {
		if (isVerificationContent && timer > 0) {
			setIsResendEnabled(false);
			const countdown = setTimeout(() => setTimer(timer - 1), 1000);
			return () => clearTimeout(countdown);
		} else {
			setIsResendEnabled(true);
		}
	}, [timer, isVerificationContent]);

	const loginSchema = Yup.object().shape({
		fullName: Yup.string()
			.min(3, 'Minimum 3 characters')
			.max(50, 'Maximum 50 characters')
			.matches(/^([A-Z][a-z]*)(\s[A-Z][a-z]*)*$/, 'Only Alphabets are allowed')
			.required('Please enter your full name'),
		email: Yup.string()
			.matches(
				/^([a-zA-Z0-9]+)([_\.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+)\.([a-zA-Z]{2,})$/,
				"Invalid Email format"
			)
			.matches(
				/^[^@]+@[^@]+\.[^.]+$/,
				"Email must contain only one '.' after '@'"
			)
			.email("Please enter a valid email address")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required("Email is required"),
		mobileNumber: Yup.string()
			.length(10, 'Mobile number must be of 10 digits')
			.matches(/^[6-9]\d{9}$/, 'Mobile number must start with 6-9')
			.required('Please enter your mobile number'),
		country: Yup.string().required('Country is required'),
		state: Yup.string().required('State is required'),
	});

	const { selectedCountryId, selectedStateId } = useUserAddressContext();
	const { user } = useUserContext();

	const [loadingNewUrl, setLoadingNewUrl] = useState(false);

	const gstOverSellPrice = useMemo(() => {
		const gst = ((course?.sellPrice ?? 0) / 100) * 18;
		return parseFloat(gst.toFixed(2));
	}, [course]);

	const totalPayable = useMemo(() => course?.sellPrice ?? 0, [course]);

	const initialValues: FormType = useMemo(() => {
		return {
			fullName: '',
			email: '',
			mobileNumber: '',
			country: selectedCountryId,
			state: selectedStateId,
			isSubscribed: true,
		};
	}, [selectedStateId, selectedCountryId]);

	const [userState, setUserState] = useState<FormType>(initialValues);

	const handleRegisterNewStudent = async (values: FormType) => {
		const newStudentRegistration = await newStudentRegister({
			variables: {
				studentRegisterInput: {
					email: values.email,
					mobileNo: values.mobileNumber,
					name: values.fullName,
					countryId: values.country,
					isNewUser: true,
					stateId: values.state,
					isSubscribed: values.isSubscribed,
				},
			},
			onCompleted: (data) => {
				if (data.newStudentRegister.is_success) {
					setIsVerificationContent(true);
					setIsResendEnabled(false);
					setTimer(89);
				} else {
					// toast.error('There was some error. Please try again!');
				}
			},
			onError: () => setIsVerificationContent(false),
		});
		if (newStudentRegistration?.data?.newStudentRegister?.is_success) {
			const newAccessToken = newStudentRegistration?.data?.newStudentRegister?.access_token;

			localStorage.setItem(VERIFY_USER_STORAGE_KEY, newAccessToken);
			refetchLocalStorageTokenDetails();
		} else {
			toast.error(newStudentRegistration?.data?.newStudentRegister?.message);
			localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
		}
	};

	const handleVerifyOtp = async (otp: string) => {
		const verifyUserValue = localStorage.getItem(VERIFY_USER_STORAGE_KEY);

		await verifyOtp({
			variables: {
				studentRegisterVerificationInput: {
					otp,
					isNewUser: true,
				},
			},
			context: {
				headers: {
					authorization: verifyUserValue,
				},
			},
			onCompleted: async (data) => {
				if (data?.newStudentverification?.is_success) {
					const newAuthData = data.newStudentverification;

					// ✅ Save new token to local storage
					localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(newAuthData));
					localStorage.removeItem(VERIFY_USER_STORAGE_KEY);

					// ✅ Update Context with new token
					refetchLocalStorageTokenDetails();

					// ✅ Force fetch updated user data
					const { data: userData } = await getUserByToken(newAuthData.access_token);
					if (userData?.data?.getMe) {
						setUser({ ...userData.data.getMe }); // ✅ Ensure React detects change
					}

					// ✅ Proceed with free course enrollment
					await enrollFreeCourse({
						variables: {
							createFreeCourseEnrollmentInput: {
								courseId: course.id,
								countryId: selectedCountryId,
								stateId: selectedStateId,
							},
						},
						context: {
							headers: {
								authorization: newAuthData.access_token,
							},
						},
						onCompleted: async () => {
							await getUserCourses();
							navigate(getDetailsSubUrl(course.slug));
							toast(
								<div className="d-flex w-100 justify-content-center align-items-center flex-row gap-3">
									Your login credentials have been sent to your registered email address. Please
									check your inbox.
									<Image
										src={toAbsoluteUrl('/media/course-detail-images/cancel.svg')}
										onClick={() => toast.dismiss()}
									/>
								</div>,
								{
									duration: Infinity,
									style: { backgroundColor: 'var(--color21)' },
									position: 'top-right',
								},
							);
						},
						onError: (err: ApolloError) => {
							toast.error(err.message);
						},
					});
				} else {
					toast.error(data?.newStudentverification?.message);
				}
			},
		});
	};

	const handleSubmit = async (values: FormType) => {
		if (course?.costType === CourseCostType.PAID) {
			const cartItems: CreateOrderDetailsInput[] = [
				{
					courseId: course.id,
					noOfSeat: 1,
					price: course?.amount,
					totalPrice: totalPayable,
					subTotal: course?.sellPrice ?? 0,
					tax: 18,
					taxAmount: gstOverSellPrice,
				},
			];
			createOrderNewUser({
				variables: {
					createOrderNewUser: {
						fullName: values.fullName,
						stateId: values.state ?? '',
						countryId: values.country ?? '',
						cartitems: cartItems,
						mobileNo: values.mobileNumber,
						gstNo: '',
						email: values.email,
						isSubscribed: values.isSubscribed,
					},
				},
				onCompleted: (data: ApiResponse) => {
					toast.success('Successfully created Order', {
						style: {
							padding: '16px',
						},
					});
					setLoadingNewUrl(true);
					window.location.href = data.createOrderNewUser.short_url;
				},
				onError: (err: any) => {
					toast.error(err.message);
					setLoadingNewUrl(false);
				},
			});
		} else {
			//Verify non logged in user for free course
			setUserState(values);
			await handleRegisterNewStudent(values);
		}
	};

	return (
		<>
			{!isVerificationContent ? (
				<div className="d-flex flex-column gap-3">
					<div className="d-flex flex-sm-row flex-column justify-content-between align-items-center gap-2">
						<span className="g_mid fs-28">Guest Sign Up</span>
					</div>
					<Formik
						onSubmit={handleSubmit}
						initialValues={initialValues}
						validationSchema={loginSchema}>
						{({ errors, touched, setFieldValue, dirty, handleSubmit: handleSubmitFormProp }) => (
							<div className="d-flex flex-column gap-3">
								<div className="fv-row d-flex flex-column">
									<label className="profile-labels g_mid fs-16">
										Full Name <span className="text-danger">*</span>
									</label>
									<Field
										name="fullName"
										type="text"
										placeholder="Enter Full Name"
										autoComplete="off"
										onInput={(e) => {
											e.target.value = e.target.value
												.replace(/[^A-Za-z\s]/g, '')
												.trimStart()
												.replace(/\s+/g, ' ')
												.split(' ')
												.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
												.join(' ');
										}}
										className={`form-control input-inner-style g_reg w-100 ${!touched.fullName
												? ''
												: touched.fullName && errors.fullName
													? 'is-invalid'
													: 'is-valid'
											}`}
									/>

									<ErrorMessage name="fullName">
										{(msg) => (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>

								<div className="fv-row d-flex flex-column">
									<label className="profile-labels g_mid fs-16">
										Email <span className="text-danger">*</span>
									</label>

									<Field
										name="email"
										type="email"
										placeholder="Enter Email Address"
										autoComplete="on"
										className={`form-control input-box-style g_reg w-100 ${!touched.email
												? ''
												: touched.email && errors.email
													? 'is-invalid'
													: 'is-valid'
											}`}
									/>

									<ErrorMessage name="email">
										{(msg) => (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>

								<div className="fv-row d-flex flex-column">
									<label className="profile-labels g_mid fs-16">
										Mobile Number <span className="text-danger">*</span>
									</label>
									<Field
										name="mobileNumber"
										type="tel"
										maxLength={10}
										placeholder="Enter Mobile Number (e.g. - 6123456789)"
										autoComplete="off"
										onInput={(e) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, '');
										}}
										className={`form-control input-box-style g_reg w-100 ${!touched.mobileNumber
												? ''
												: touched.mobileNumber && errors.mobileNumber
													? 'is-invalid'
													: 'is-valid'
											}`}
									/>
									<ErrorMessage name="mobileNumber">
										{(msg) => (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1" /> {msg}
											</div>
										)}
									</ErrorMessage>
								</div>
								{!user && (
									<>
										<StateAndCountryDropDowns
											handleStateChange={(state: string) => {
												setFieldValue('state', state);
											}}
											handleCountryChange={(country: string) => {
												setFieldValue('country', country);
											}}
										/>
										<div className="row">
											{errors.country ? (
												<div className="col-6">
													<ErrorMessage name="country">
														{(country) => (
															<div className="text-danger fs-7">
																<i className="fa fa-exclamation-circle me-1" /> {country}
															</div>
														)}
													</ErrorMessage>
												</div>
											) : (
												<div className="col-6" />
											)}
											<div className="col-6">
												<ErrorMessage name="state">
													{(state) => (
														<div className="text-danger fs-7">
															<i className="fa fa-exclamation-circle me-1" /> {state}
														</div>
													)}
												</ErrorMessage>
											</div>
										</div>
									</>
								)}
								<Form.Check
									inline
									label="Send Me Special Offers and Learning Tips"
									className="fs-16"
									type="checkbox"
									name="isSubscribed"
									defaultChecked={true}
									onChange={(e) => {
										setFieldValue('isSubscribed', e.target.checked);
									}}
								/>
								<div className="px-3">
									<Button
										buttonText={
											<span className="d-flex justify-content-center gap-2">
												{(newUserOrderDataLoading ||
													newStudentRegisterLoading ||
													loadingNewUrl) && <Loading width={50} />}
												{user || course?.costType === CourseCostType.PAID ? (
													<span>
														Proceed to Pay{'  '}
														<span>
															{RUPEE_SYMBOL}
															{totalPayable.toFixed(2)}
														</span>
													</span>
												) : (
													<span>Proceed to Verify</span>
												)}
											</span>
										}
										buttonType={ButtonType.PRIMARY_BUTTON}
										classes={`g_mid w-100 ${newUserOrderDataLoading ? 'opacity-50' : ''}`}
										type="submit"
										buttonBorderType={ButtonBorderType.BLACK_BORDER}
										handleClick={handleSubmitFormProp}
									/>
								</div>
								<div className="text-center">
									<span className="payment-sub-desc g_reg">
										By proceeding you agree to our Terms, Privacy & Refund Policy
									</span>
								</div>
							</div>
						)}
					</Formik>
				</div>
			) : (
				<div>
					<div className="d-flex justify-content-between pb-2">
						<span className="g_mid fs-28">Verify Email</span>
						<Image src={toAbsoluteUrl('/media/course/verifyEmail.svg')} />
					</div>
					<div className="pb-2">
						<p className="color1">Please enter the 6-digit code sent to</p>
						<p className="color3">{userState.email}</p>
					</div>
					<Formik
						initialValues={{ otp: '' }}
						onSubmit={(values) => handleVerifyOtp(values.otp)}
						validationSchema={Yup.object().shape({
							otp: Yup.string().required('Please enter OTP'),
						})}>
						{({ errors, touched, values, dirty, handleSubmit: handleVerifyOtpSubmit }) => (
							<div className="fv-row d-flex flex-column">
								<label className="profile-labels g_mid fs-16">
									OTP <span className="text-danger">*</span>
								</label>
								<Field
									name="otp"
									placeholder="OTP"
									autoComplete="off"
									onInput={(e) => {
										e.target.value = e.target.value.replace(/[^0-9]/g, '');
									}}
									className={`form-control input-box-style g_reg w-100 ${!touched.otp ? '' : touched.otp && errors.otp ? 'is-invalid' : 'is-valid'
										}`}
								/>
								<ErrorMessage name="otp">
									{(msg) => (
										<div className="text-danger fs-7 mt-2">
											<i className="fa fa-exclamation-circle me-1" /> {msg}
										</div>
									)}
								</ErrorMessage>
								<p className="veri-desc mt-2 text-start">
									{isResendEnabled ? (
										<button
											type="button"
											className={`btn resend-btn border-0 ${loadingOtp ? 'disabled' : ''}`}
											onClick={() => handleRegisterNewStudent(userState)}>
											Resend OTP
										</button>
									) : (
										<>
											Please wait &nbsp;
											<span className="veri-desc-strong">{timer}s</span>
											&nbsp; before requesting another code
										</>
									)}
								</p>
								<Button
									buttonText={
										<span className="d-flex justify-content-center gap-2">
											{newStudentRegisterLoading && <Loading width={50} />}
											<span>Proceed to Enroll</span>
										</span>
									}
									buttonType={ButtonType.PRIMARY_BUTTON}
									classes={`g_mid w-100 ${newStudentRegisterLoading ? 'opacity-50' : ''}`}
									type="submit"
									handleClick={handleVerifyOtpSubmit}
									buttonBorderType={ButtonBorderType.BLACK_BORDER}
									disabled={!values.otp || Object.keys(errors).length > 0 || !dirty}
								/>
							</div>
						)}
					</Formik>
				</div>
			)}
		</>
	);
};

export default LoginNewUser;
