import React, { useEffect, useState } from 'react';
import PaymentInformation from '../CommonComponents/PaymentInformation';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, COURSE_URL } from 'pages/routing/routePages';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { VERIFY_ORDER_ADMIN_MUTATION, VERIFY_ORDER_NEW_USER } from 'components/Cart/core/requests';
import toast from 'react-hot-toast';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import { useUserContext } from 'context/UserContext';
import { NewUserOrderObjectType, OrderObjectType, OrderStatusEnum } from 'generated/types';
import { AUTH_LOCAL_STORAGE_KEY } from 'pages/auth/core/AuthHelpers';
import { Course, CourseTypeEnum } from 'components/_v2/Course/type';
import { useZoomMeeting } from 'context/ZoomMeetingContext';

const GET_COURSE_SPECIFIC_NOTIFICATIONS = gql`
	subscription GetNotifications($topic: String!) {
		getNotifications(topic: $topic) {
			message
			title
		}
	}
`;

type DataType =
	| { verifyOrderAdmin: OrderObjectType }
	| { verifyOrderNewUser: NewUserOrderObjectType };

function isAdminData(data: DataType): data is { verifyOrderAdmin: OrderObjectType } {
	return 'verifyOrderAdmin' in data;
}

const PaymentStatus = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { courses, hasValueSet, setUserCourses } = useCourseContext();
	const { user, refetchLocalStorageTokenDetails } = useUserContext();
	const { getCourses, getUserCourses } = useCourses();
	const { isZoomVisited } = useZoomMeeting();
	const queryParams = new URLSearchParams(location.search);
	const razorpay_payment_link_id = queryParams.get('razorpay_payment_link_id');
	const courseId = queryParams.get('courseId');
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [courseSlug, setCourseSlug] = useState<string | null>(null);
	const [courseName, setCourseName] = useState<string | null>(null);

	if (!razorpay_payment_link_id) {
		navigate(BASE_PAGE_URL);
	}

	const [status, setStatus] = useState<OrderStatusEnum>(OrderStatusEnum.Pending);

	const getCourseDetails = (courseId: string): Course =>
		courses.find((course) => course.id === courseId);

	const courseDetailsData = getCourseDetails(courseId);
	const isCourseWebinar = courseDetailsData?.courseType?.slug === CourseTypeEnum.WEBINAR;

	const [verifyOrder] = useMutation(user ? VERIFY_ORDER_ADMIN_MUTATION : VERIFY_ORDER_NEW_USER, {
		variables: {
			verifyOrderInputData: {
				transactionId: razorpay_payment_link_id,
			},
		},
		onError: async (err: any) => {
			if (courseId) {
				const courseDetails = getCourseDetails(courseId);
				setCourseSlug(courseDetails.slug);
				setCourseName(courseDetails.name);
			}
			if (err.message === 'Student has already enrolled for these courses!') {
				toast.success('Payment verified successfully!');
				setStatus(OrderStatusEnum.Paid);
			} else {
				toast.error(err.message);
				navigate(COURSE_URL);
			}
		},
		onCompleted: async (data: DataType) => {
			if (courseId) {
				const courseDetails = getCourseDetails(courseId);
				setIsSubscribed(true);
				toast.success('Payment verified successfully!');
				setStatus(data[user ? 'verifyOrderAdmin' : 'verifyOrderNewUser'].status);
				setCourseSlug(courseDetails?.slug);
				setCourseName(courseDetails?.name);
				// setUserCourses([]);
				// ✅ Check if user is new and needs to be logged in
				if (!isAdminData(data)) {
					const newAccessToken = data.verifyOrderNewUser.access_token;

					// ✅ Save new token BEFORE fetching user
					localStorage.setItem(
						AUTH_LOCAL_STORAGE_KEY,
						JSON.stringify({ access_token: newAccessToken }),
					);

					// ✅ Ensure token is updated before fetching user
					await refetchLocalStorageTokenDetails();
				}

				await getUserCourses();
			}
		},
	});

	useEffect(() => {
		if (isZoomVisited) {
			window.location.reload();
		}
	}, [isZoomVisited]);

	useEffect(() => {
		const getPublicCourses = async () => {
			if (!hasValueSet) {
				await getCourses().finally(() => {
					verifyOrder();
				});
			} else {
				verifyOrder();
			}
		};

		getPublicCourses();
	}, []);

	useSubscription(GET_COURSE_SPECIFIC_NOTIFICATIONS, {
		skip: !isSubscribed || !courseSlug,
		variables: { topic: courseSlug! },
		onSubscriptionData: ({ subscriptionData }) => {
			const notification = subscriptionData.data.getNotifications;
			toast.success(`Notification: ${notification.title} - ${notification.message}`);
		},
	});

	return (
		<div>
			{status === OrderStatusEnum.Paid && (
				<PaymentInformation
					image={`${toAbsoluteUrl('/media/payment-details/success.png')} 1x, ${toAbsoluteUrl(
						'/media/payment-details/success@2x.png',
					)} 2x, ${toAbsoluteUrl('/media/payment-details/success@3x.png')} 3x`}
					alt="Payment_Success"
					title="Payment Success"
					webinarCourse={
						isCourseWebinar
							? {
									courseId: courseDetailsData.id,
									webinarStartTime: courseDetailsData.startDatetime,
									webinarEndTime: courseDetailsData.endDatetime,
							  }
							: undefined
					}
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							was successful. You can find the {isCourseWebinar ? 'webinar' : 'course'} in my
							courses section.
						</>
					}
				/>
			)}
			{status === OrderStatusEnum.Pending && (
				<PaymentInformation
					image={`${toAbsoluteUrl('/media/payment-details/pending.png')} 1x, ${toAbsoluteUrl(
						'/media/payment-details/pending@2x.png',
					)} 2x, ${toAbsoluteUrl('/media/payment-details/pending@3x.png')} 3x`}
					alt="Payment_Pending"
					title="Payment Is Pending"
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							is still in review. You can find the {isCourseWebinar ? 'webinar' : 'course'} in my
							courses section once the payment is complete.
						</>
					}
					hideButton
				/>
			)}
			{status === OrderStatusEnum.Failed && (
				<PaymentInformation
					image={`${toAbsoluteUrl('/media/payment-details/failed-payment.png')} 1x, ${toAbsoluteUrl(
						'/media/payment-details/failed-payment@2x.png',
					)} 2x, ${toAbsoluteUrl('/media/payment-details/failed-payment@3x.png')} 3x`}
					alt="Payment_Failed"
					title="Payment Has Failed"
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							has failed. Please try again. You can find the
							{isCourseWebinar ? 'webinar' : 'course'} in my courses section once the payment is
							complete.
						</>
					}
					hideButton
				/>
			)}
		</div>
	);
};

export default PaymentStatus;
