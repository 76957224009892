import React, { useMemo } from 'react';
import './hgfooter.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import {
	ABOUT_US,
	BASE_PAGE_URL,
	CONTACT_US,
	COURSE_URL,
	MY_COURSE_URL,
	PRIVACY_POLICY_URL,
	TNC_URL,
} from 'pages/routing/routePages';
import { BLOGS_SECTION, FAQS_SECTION } from 'utils/consts';
import { useUserContext } from 'context/UserContext';

interface Links {
	text: string;
	onClick: () => void;
}

interface FooterData {
	title: string;
	links: Links[];
	classes: string;
}

const FooterLink = ({ title, links, classes }: FooterData) => (
	<div className={`col-lg-3 col-md-6 ${classes} mt-lg-0 mt-md-4 mt-4`}>
		<h2 className="footer-links-title lora">{title}</h2>
		<ul className="pt-lg-3 pt-md-1 px-0 pt-1 ">
			{links.map((link: any, index: any) => (
				<li key={index} className="footer-links my-2">
					<span className="links white cursor-pointer" onClick={link.onClick}>
						{link.text}
					</span>
				</li>
			))}
		</ul>
	</div>
);

const HGFooter = () => {
	const navigate = useNavigate();
	const { user } = useUserContext();

	const coursesLinks = (isLoggedIn: boolean) => {
		const links = [{ text: 'All Courses', onClick: () => navigate(COURSE_URL) }];
		if (isLoggedIn) {
			links.push({ text: 'My Learning', onClick: () => navigate(MY_COURSE_URL) });
		}
		return links;
	};

	const footerData: FooterData[] = useMemo(
		() => [
			{
				title: 'Learning',
				links: coursesLinks(!!user),
				classes: 'col-6',
			},
			{
				title: 'Resources',
				links: [
					{ text: 'Blogs', onClick: () => navigate(`${BASE_PAGE_URL}#${BLOGS_SECTION}`) },
					{ text: 'FAQ’s', onClick: () => navigate(`${BASE_PAGE_URL}#${FAQS_SECTION}`) },
				],
				classes: 'col-6',
			},
			{
				title: 'Quick Links',
				links: [
					{ text: 'Privacy Policy', onClick: () => navigate(PRIVACY_POLICY_URL) },
					{ text: 'Terms & Conditions', onClick: () => navigate(TNC_URL) },
					{ text: 'Contact Us', onClick: () => navigate(CONTACT_US) },
					{ text: 'About Us', onClick: () => navigate(ABOUT_US) },
				],
				classes: 'col-12',
			},
		],
		[user],
	);

	const socialMediaLinks: Links[] = useMemo(
		() => [
			{
				text: 'x',
				onClick: () => window.open('https://x.com/hogrowth', '_blank', 'noopener,noreferrer'),
			},
			{
				text: 'facebook',
				onClick: () =>
					window.open('https://facebook.com/hogrowth', '_blank', 'noopener,noreferrer'),
			},
			{
				text: 'linkedin',
				onClick: () =>
					window.open(
						'https://in.linkedin.com/in/hogrowth-powered-by-jainam-01b997308',
						'_blank',
						'noopener,noreferrer',
					),
			},
			{
				text: 'youtube',
				onClick: () =>
					window.open('https://www.youtube.com/@hogrowth', '_blank', 'noopener,noreferrer'),
			},
			{
				text: 'instagram',
				onClick: () =>
					window.open('https://instagram.com/hogrowthofficial', '_blank', 'noopener,noreferrer'),
			},
		],
		[],
	);

	return (
		<section className="footer-wrapper" id="footer">
			<div className="container pt-5">
				<div className="row mb-5">
					<div className="col-lg-10 col-md-11 col-11 layout-set d-flex">
						<div className="row">
							{/* Logo and Text and Social Icon */}
							<div className="col-lg-3 col-md-12 col-12 footer-social-icon flex-wrap">
								<div className="col-lg-12 col-12 ">
									<img src={toAbsoluteUrl('/media/logo/hoGrowthWhite.svg')} alt="HoGrowth_logo" />
									<p className="g_reg footer-short-desc pb-0">
										Empower your finance team. The one stop text for all financial management from
										small to medium-sized businesses.
									</p>
								</div>

								<div className="col-lg-12 col-12 align-content-center">
									<ul className="d-flex gap-4 p-0">
										{socialMediaLinks.map((link, index) => (
											<li
												key={index}
												// className={`nav-item ms-lg-0 ${index === 0 ? 'ms-0' : 'ms-4'}`}>
												className={`list-group-item ${index === 0 ? 'ms-0' : 'ms-lg-3 ms-1'}`}>
												<a
													className="active"
													role="button"
													aria-current="page"
													onClick={link.onClick}>
													<img
														width={28}
														height={28}
														src={toAbsoluteUrl(`/media/footer-images/hg-${link.text}.svg`)}
														alt={link.text.charAt(0).toUpperCase() + link.text.slice(1)}
													/>
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>

							{/* footer-links-responsive */}
							<div className="offset-lg-1 col-lg-8 col-md-12 col-12 d-flex footer-links-responsive flex-wrap">
								{footerData.map((section, index) => (
									<FooterLink
										key={index}
										title={section.title}
										links={section.links}
										classes={section.classes}
									/>
								))}
								<div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-md-4 mt-4">
									<h2 className="footer-links-title lora mb-3">Get In Touch</h2>
									<div className="d-flex">
										<div>
											<img
												src={toAbsoluteUrl('/media/footer-images/location-n.png')}
												alt="Location"
											/>
										</div>
										<div className="footer-get-touch g_reg">
											{/* F-7, 3 Floor, VIP Plaza, VIP Road, near Shyam Baba Mandir, Vesu, Surat,
											Gujarat 395007. */}
											<a
												href="https://www.google.com/maps?q=F-7,+3+Floor,+VIP+Plaza,+VIP+Road,+near+Shyam+Baba+Mandir,+Vesu,+Surat,+Gujarat+395007"
												target="_blank"
												rel="noopener noreferrer"
												style={{ textDecoration: 'none', color: 'inherit' }}
											>
												F-7, 3 Floor, VIP Plaza, VIP Road, near Shyam Baba Mandir, Vesu, Surat, Gujarat 395007.
											</a>
										</div>
									</div>
									<div className="d-flex">
										<div>
											<img src={toAbsoluteUrl('/media/footer-images/mail-n.png')} alt="Gmail" />
										</div>
										{/* <div className="footer-get-touch g_reg">support@hogrowth.com</div> */}
										<div className="footer-get-touch g_reg">
											<a
												href="mailto:support@hogrowth.com"
												style={{ textDecoration: 'none', color: 'inherit' }}
											>
												support@hogrowth.com
											</a>
										</div>
									</div>
									<div className="d-flex">
										<div>
											<img src={toAbsoluteUrl('/media/footer-images/call-n.png')} alt="Call" />
										</div>
										{/* <div className="footer-get-touch g_reg">+91 97277 50015</div> */}
										<div className="footer-get-touch g_reg">
											<a
												href="tel:+919727750015"
												style={{ textDecoration: 'none', color: 'inherit' }}
											>
												+91 97277 50015
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="row">
					<div className="col-lg-10 layout-set border-bottom mt-5"></div>
				</div> */}

				<div className="row">
					{/* <div className=" px-lg-5 px-sm-3 my-4 px-2"> */}
					<div className="col-lg-10 layout-set">
						<div className="footer-bottom-border mb-5" />
						<p className="g_reg footer-terms mb-5 text-center">
							© 2024 HoGrowth. All rights reserved | Cookie Settings, Anti-Spam, Privacy, User
							agreement, Legal Notice and Responsible Disclosure
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HGFooter;
