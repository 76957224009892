import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { CountryObjectType } from 'generated/types';
import { useUserContext } from './UserContext';

interface DataArrayType {
	value: string;
	label: string;
}
// Define the shape of the context state
interface UserAddressState {
	countries: DataArrayType[];
	setCountries: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	allCountryData: CountryObjectType[];
	setAllCountryData: React.Dispatch<React.SetStateAction<CountryObjectType[]>>;
	states: DataArrayType[];
	setStates: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	cities: DataArrayType[];
	setCities: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	selectedCountryId: string;
	setSelectedCountryId: React.Dispatch<React.SetStateAction<string>>;
	selectedStateId: string;
	setSelectedStateId: React.Dispatch<React.SetStateAction<string>>;
	selectedCityId: string;
	setSelectedCityId: React.Dispatch<React.SetStateAction<string>>;
	changeCountry: (newCountry: DataArrayType) => void;
	changeState: (newState: DataArrayType, updateFormikCity?: (cityId: string) => void) => void;
}

// Create the context with a default value
const UserAddress = createContext<UserAddressState | undefined>(undefined);

// Create a provider component
export const UserAddressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [allCountryData, setAllCountryData] = useState<CountryObjectType[]>([]);
	const [countries, setCountries] = useState<DataArrayType[]>([]);
	const [states, setStates] = useState<DataArrayType[]>([]);
	const [cities, setCities] = useState<DataArrayType[]>([]);
	const [selectedCountryId, setSelectedCountryId] = useState<string>('');
	const [selectedStateId, setSelectedStateId] = useState<string>('');
	const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
	const [selectedCityId, setSelectedCityId] = useState<string>('');
	const { user } = useUserContext();

	const changeCountry = (newCountry: DataArrayType) => {
		setSelectedCountryId(newCountry.value);
		const selectedCountryData = allCountryData.find((country) => country.id === newCountry.value);

		setStates(
			selectedCountryData?.states?.map((state) => ({
				label: state?.stateName,
				value: state?.id,
			})) || [],
		);

		setSelectedStateId('');
		setCities([]);
		setSelectedCityId('');
	};

	const changeState = (newState: DataArrayType, updateFormikCity?: (cityId: string) => void) => {
		setIsManuallyUpdated(true);
		setSelectedStateId(newState.value);

		// Find the selected state in allCountryData
		const selectedCountryStates = allCountryData.find(
			(country) => country.id === selectedCountryId,
		)?.states;

		// Get cities for the selected state
		const newStateCities = selectedCountryStates?.find(
			(state) => state?.id === newState.value,
		)?.cities;

		if (newStateCities?.length) {
			const formattedCities = newStateCities.map((city) => ({
				value: city?.id,
				label: city?.cityName,
			}));

			setCities(formattedCities);
			setSelectedCityId('');

			if (updateFormikCity) {
				updateFormikCity('');
			}
		}
	};

	useEffect(() => {
		if (user?.id) {
			const userProfileDetails = user.userProfile?.[0];
			const userAddressDetails = user.userAddress?.[0];

			if (!selectedCountryId && userProfileDetails?.countryId) {
				changeCountry({
					label: userProfileDetails?.country?.countryName || '',
					value: userProfileDetails?.countryId || '',
				});
			}

			if (!selectedStateId && userAddressDetails?.stateId) {
				setSelectedStateId(userAddressDetails?.stateId);
			}

			const selectedCountryStates = allCountryData.find(
				(country) => country.id === userProfileDetails?.countryId,
			)?.states;

			const storedStateCities = selectedCountryStates?.find(
				(state) => state.id === userAddressDetails?.stateId,
			)?.cities;

			if (storedStateCities?.length) {
				const formattedCities = storedStateCities.map((city) => ({
					value: city.id,
					label: city.cityName,
				}));

				setCities(formattedCities);

				if (!isManuallyUpdated) {
					setTimeout(() => {
						setSelectedCityId(userAddressDetails?.cityId || '');
					}, 0);
				}
			}
		}
	}, [user?.id, isManuallyUpdated, allCountryData]);

	return (
		<UserAddress.Provider
			value={{
				countries,
				setCountries,
				states,
				setStates,
				cities,
				setCities,
				selectedCountryId,
				setSelectedCountryId,
				selectedStateId,
				setSelectedStateId,
				selectedCityId,
				setSelectedCityId,
				allCountryData,
				setAllCountryData,
				changeCountry,
				changeState,
			}}>
			{children}
		</UserAddress.Provider>
	);
};

// Create a custom hook to use the context
export const useUserAddressContext = (): UserAddressState => {
	const context = useContext(UserAddress);
	if (!context) {
		throw new Error('useUserAddress must be used within a UserAddressProvider');
	}
	return context;
};
