import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import './hgheader.css';
import { BLOGS_SECTION, FAQS_SECTION, STORIES_SECTION } from 'utils/consts';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, COURSE_URL, WEBINAR_URL, ZOOM_MEETING } from 'pages/routing/routePages';
import { useUserContext } from 'context/UserContext';
import LoggedInUser from './LoggedInUser';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import ProfileListItems from 'components/_v2/Header/ProfileListItems';
import { Offcanvas, Button } from 'react-bootstrap';

interface NavigationEvent extends React.MouseEvent<HTMLAnchorElement, MouseEvent> { }

const HGHeader = () => {
	const { user } = useUserContext();
	const { isZoomVisited } = useZoomMeeting();
	const isSmallScreen = useIsSmallScreen(768);
	const isTabletView = useIsSmallScreen(1200);
	const navigate = useNavigate();
	const location = useLocation();
	const isZoomMeetingPage = location.pathname.includes(ZOOM_MEETING);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//Remove header on Zoom Meeting page
	if (isZoomMeetingPage || isZoomVisited) return null;

	const headerlinks = (headerlink: string, id: string) => {
		return (
			<li
				className="header-nav-item nav-item black"
				onClick={() => navigate(`${BASE_PAGE_URL}#${id}`)}>
				<span style={{ cursor: 'pointer' }}>{headerlink}</span>
			</li>
		);
	};

	const headerLink = (headerLink: string, url: string, classes?: string) => {
		const handleNavigation = (e: NavigationEvent) => {
			if (location.pathname === url) {
				e.preventDefault(); // Prevents default navigation
				navigate(`${url}?searchQuery=`, { replace: true }); // Forces re-render with empty searchQuery
			}
		};
		return (
			<li className="header-nav-item nav-item black">
				<Link className="active" aria-current="page" to={url} onClick={handleNavigation}>
					<span className={classes}> {headerLink}</span>
				</Link>
			</li>
		);
	};

	return (
		<nav className="header-wrapper navbar navbar-white fixed-top px-lg-2 nav b">
			<div className="container-fluid w-100 col-lg-10 mx-lg-auto px-lg-4 col-md-12 py-lg-1 py-md-1 px-sm-2">
				<Link to="/">
					<img src={toAbsoluteUrl(`/media/logo/hoGrowthBlack.svg`)} alt="HoGrowth_logo" />
				</Link>

				<ul className="navbar-nav text-center">
					{headerLink('Courses', COURSE_URL)}
					{headerlinks('Student Stories', STORIES_SECTION)}
					{headerlinks('FAQs', FAQS_SECTION)}
					{headerlinks('Articles', BLOGS_SECTION)}
					{/* <li
						style={{ height: '30px' }}
						className="border-light-background header-nav-item nav-item black align-middle"
					/> */}
					{headerLink('Webinar', WEBINAR_URL)}
				</ul>

				<div className="auth d-flex">
					{/* Login and Signup Button and After login view */}
					<div className="justify-content-end">
						{user ? (
							// <div className={`align-items-center ${isSmallScreen ? "ms-5" : ""}`}>
							<div className={`align-items-center me-md-4 me-3`}>
								<LoggedInUser
									user={user}
									userProfileIcon={!isTabletView}
									notification_icon={true}
									disableClick={false}
								/>
							</div>
						) : (
							<div className="authorization d-xl-inline-flex d-none me-md-4">
								<LoginButton />
								<SignupButton />
							</div>
						)}
					</div>

					{/* Hamburg Icon View */}
					<Button
						className="navbar-toggler custom-toggler bg-backgroundGlobal border-0"
						style={{
							backgroundColor: 'var(--backgroundGlobal) !important',
							boxShadow: 'none',
						}}
						onClick={handleShow}
						aria-controls="offcanvasDarkNavbar">
						<img
							src={toAbsoluteUrl(`/media/hgheader/hamburger-menu-black.svg`)}
							alt="HoGrowth_logo"
							width="35px"
							height="40px"
						/>
					</Button>

					<Offcanvas
						show={show}
						onHide={handleClose}
						placement="end"
						scroll
						backdrop
						className="drawer-align text-bg-white overflow-y-auto"
						style={{ zIndex: 1111 }}>
						<Offcanvas.Header closeButton className="nav_close_btn">
							<Offcanvas.Title id="offcanvasDarkNavbarLabel" />
						</Offcanvas.Header>
						<Offcanvas.Body className="p-0">
							<div className="justify-content-end mt-3">
								{user ? (
									<div className="mt-2">
										<LoggedInUser
											user={user}
											userProfileIcon={isTabletView}
											notification_icon={false}
											disableClick={isTabletView}
										/>
									</div>
								) : (
									<div className="nav-auth-btn d-flex" onClick={handleClose}>
										<LoginButton />
										<SignupButton />
									</div>
								)}
							</div>
							<div className={`${user ? 'mt-md-4 border-bottom mt-4' : 'mt-md-0 mt-2 border-0'}`} />
							{user && (
								<div className="px-4 pt-4" onClick={handleClose}>
									<ProfileListItems />
								</div>
							)}
							{(user || isSmallScreen) && <div className="border-bottom mt-4" />}
							<div className="white">
								<ul className="list-unstyled d-flex flex-column">
									<li className="nav-item" onClick={handleClose}>
										<Link
											className="nav-link nav-text ps-4 ms-3 py-0"
											to={COURSE_URL}
											style={{ fontSize: '0.9rem' }}>
											Courses
										</Link>
									</li>
									<li className="nav-item" onClick={handleClose}>
										<Link
											className="nav-link nav-text ps-4 ms-3 py-0"
											to={`${BASE_PAGE_URL}#${STORIES_SECTION}`}
											style={{ fontSize: '0.9rem' }}>
											Student Stories
										</Link>
									</li>
									<li className="nav-item" onClick={handleClose}>
										<Link
											className="nav-link nav-text ps-4 ms-3 py-0"
											to={`${BASE_PAGE_URL}#${FAQS_SECTION}`}
											style={{ fontSize: '0.9rem' }}>
											FAQs
										</Link>
									</li>
									<li className="nav-item" onClick={handleClose}>
										<Link
											className="nav-link nav-text ps-4 ms-3 py-0"
											to={`${BASE_PAGE_URL}#${BLOGS_SECTION}`}
											style={{ fontSize: '0.9rem' }}>
											Articles
										</Link>
									</li>
									<li className="nav-item" onClick={handleClose}>
										<Link
											className="nav-link nav-text ps-4 ms-3 py-0"
											to={WEBINAR_URL}
											style={{ fontSize: '0.9rem', color: '#24272C' }}>
											Webinar
										</Link>
									</li>
								</ul>
							</div>
						</Offcanvas.Body>
					</Offcanvas>
				</div>
			</div>
		</nav>
	);
};

export default HGHeader;
