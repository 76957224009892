import {
	CourseTypeEnum,
	LessonStatusEnum,
	LessonType,
	LessonTypeEnum,
} from 'components/_v2/Course/type';
import LessonStatus from 'components/_v2/ViewCourseData/LessonTypesComponents/LessonStatus';
import LessonContent from 'components/_v2/ViewCourseData/LessonTypesComponents/LessonContent';
import CourseCompleted from 'components/_v2/ViewCourseData/CourseCompleted';
import LoadingContent from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/LoadingContent';
import React from 'react';

const DocumentType = ({
	lessonData,
	courseId,
	showCertificate,
	loadingContent,
	hasCertificate,
	courseTypeSlug,
}: {
	lessonData: LessonType;
	courseId: string;
	showCertificate: boolean;
	loadingContent: boolean;
	hasCertificate: boolean;
	courseTypeSlug: string;
}) => {
	if (loadingContent) {
		return <LoadingContent />;
	}

	if (showCertificate) {
		return (
			<CourseCompleted
				hasCertificate={hasCertificate}
				courseId={courseId}
				endDate={lessonData.completedAt}
				courseType={courseTypeSlug}
			/>
		);
	}

	return (
		<>
			{lessonData.type !== LessonTypeEnum.MEETING &&
				lessonData.status !== LessonStatusEnum.COMPLETED ? (
				<span className="d-none d-sm-block lora lessonHeading px-4 text-center">
					{lessonData?.name}
				</span>
			) : (
				<></>
			)}
			{courseTypeSlug !== CourseTypeEnum.RECORDED_COURSE &&
				lessonData.type !== LessonTypeEnum.DOCS ? (
				<LessonStatus lessonData={lessonData} />
			) : null}
			<LessonContent lessonData={lessonData} courseId={courseId} courseType={courseTypeSlug} />
		</>
	);
};

export default DocumentType;
