export interface QuizObjectType {
	createdAt: Date;
	createdById: string;
	duration?: number;
	eligibleMarksForCertificate: number;
	hasTimeLimit: boolean;
	id: string;
	questions: QuizQuestionObjectType[];
	status: QuizStatusEnum;
	title: string;
	totalMarks: number;
	type: QuizTypesEnum;
	updatedAt: Date;
	updatedById?: string;
}

export interface QuizQuestionObjectType {
	answers: string[];
	createdAt?: Date;
	createdById: string;
	duration?: number;
	hasMultipleAnswers: boolean;
	id: string;
	mark: number;
	options: { optionImage?: string; optionText?: string }[];
	question: string;
	questionImages?: string[];
	questionOrder: number;
	quizId: string;
	updatedAt?: Date;
	updatedById: string;
}

export enum QuizStatusEnum {
	DRAFT = 'DRAFT',
	READY_FOR_REVIEW = 'READY_FOR_REVIEW',
	IN_REVIEW = 'IN_REVIEW',
	READY_FOR_PUBLISH = 'READY_FOR_PUBLISH',
	PUBLISHED = 'PUBLISHED',
	ARCHIVE = 'ARCHIVE',
}

export enum QuizTypesEnum {
	COURSE_QUIZ = 'COURSE_QUIZ',
	LESSON_QUIZ = 'LESSON_QUIZ',
	GENERAL_QUIZ = 'GENERAL_QUIZ',
}

export type QuizSubmitResponse = {
	submitQuiz: {
		endTime: string;
		isSubmit?: boolean;
		isSuccess: boolean;
		quizSubmitTime?: string;
		questionAnswer: QuestionAnswerInputType[];
	};
};

export type QuestionAnswerInputType = {
	questionId: string;
	answer: string[];
	correctAnswer?: string;
	mark?: string;
};

export interface QuizSubmitType {
	courseId: string;
	isSubmit: boolean;
	lessonId: string;
	questionAnswer: QuestionAnswerInputType[];
	quizId: string;
	sectionId: string;
	is_start?: boolean;
	callbackFunction?: (data: QuizSubmitResponse) => void;
	isInSameCourse?: boolean;
}

export interface QuizResultResponse {
	getQuizResult: {
		quizStartTime: string;
		quizEndTime: string;
		quizSubmitTime: string;
		questionAnswer: QuestionAnswerInputType[];
		isSubmit: boolean;
		lessonId: string;
	};
}

export interface UserQuizResultStatusListObjectType {
	getStudentQuizResultStatusList: {
		quizDetails: QuizReattemptDetails[];
	};
}

export interface QuizReattemptDetails {
	sectionId: string;
	lessonId: string;
	quizId: string;
	status: QuizResultStatusEnum;
	allowReAttempt: boolean;
}

export enum QuizResultStatusEnum {
	PENDING = 'PENDING',
	FAILED = 'FAILED',
	PASSED = 'PASSED',
}
