import React, { useState } from 'react';
import './MyCourse.css';
import { SelectedTabType } from 'components/_v2/CommonComponents/types';
import CommonTabView from 'components/_v2/CommonComponents/CommonTabView';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { OrderObjectType } from 'generated/types';
import { useUserContext } from 'context/UserContext';
import { useUserAddressContext } from 'context/UserAddressContext';
import { useCourseContext } from 'context/CourseContext';
import { BASE_PAGE_URL } from 'pages/routing/routePages';
import toast from 'react-hot-toast';

const GET_STUDENT_ORDER_HISTORY = gql`
	query PaymentHistory_Query(
		$where: StudentProfileResolver_GetStudentOrderHistory_FilterInputType
		$paginate: PaginatorArgs!
	) {
		getStudentOrderHistory(where: $where, paginate: $paginate) {
			records {
				id
				userId
				subTotal
				total
				tax
				gstNo
				status
				transactionId
				paymentGatewayId
				paymentGatewayMode
				transactionStatus
				transactionDateTime
				totalDiscount
				type
				createdAt
				deletedAt
				platform
				paymentLink
				invoiceStatus
				orderDetails {
					orderId
					price
					course {
						name
					}
				}
				address_id
				companyName
				registerAddress
				userAddress {
					userId
					fullname
				}
				user {
					id
					username
				}
			}
			pagination {
				totalRecords
				currentPage
			}
		}
	}
`;
interface OrderListObjectType {
	records: OrderObjectType[];
	pagination: OrderPaginationType;
}
interface OrderPaginationType {
	totalRecords: number;
	currentPage: number;
}

interface GetStudentOrderHistoryQuery {
	getStudentOrderHistory: OrderListObjectType;
}
interface GetStudentOrderHistoryQuery {
	getStudentOrderHistory: OrderListObjectType;
}

const MyCourse = () => {
	const { search } = useLocation();
	const navigate = useNavigate();

	const { logOutUser } = useUserContext();
	const { setSelectedStateId, setSelectedCityId, setSelectedCountryId, setStates, setCities } =
		useUserAddressContext();
	const { setUserCourses } = useCourseContext();

	const [apiSource, setApiSource] = useState<'paymentDetails' | 'orderHistory' | null>(null);
	const [paymentRecords, setPaymentRecords] = useState<OrderObjectType[]>([]);
	const [whereClause, setWhereClause] = useState({});
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);


	const {
		loading: loadingOrderHistory,
		error: errorOrderHistory,
		data: dataOrderHistory,
		refetch: refetchOrderHistory,
	} = useQuery<GetStudentOrderHistoryQuery>(GET_STUDENT_ORDER_HISTORY, {
		variables: {
			where: whereClause,
			paginate: { page: currentPage - 1, per_page: 6 },
		},
		fetchPolicy: 'network-only',
		onError: (graphQLError) => {
			console.error('GraphQL error:', graphQLError);
		},
		onCompleted: (data) => {
			setApiSource('orderHistory');
			const totalRecords = data.getStudentOrderHistory.pagination.totalRecords;
			setPaymentRecords(data.getStudentOrderHistory.records);
			const perPage = 6;
			setTotalPages(Math.ceil(totalRecords / perPage));
		},
	});

	if (errorOrderHistory) {
		console.error('GraphQL error:', errorOrderHistory);
		logOutUser();
		setSelectedCityId('');
		setSelectedCountryId('');
		setSelectedStateId('');
		setUserCourses([]);
		setStates([]);
		setCities([]);
		navigate(BASE_PAGE_URL);
		toast.success('Logout successfully');

	}
	const selectedTabType = new URLSearchParams(search).get('courseMode') as SelectedTabType;

	return (
		<section style={{ backgroundColor: 'white' }}>
			{/*<div className="container-fluid">*/}
			{/*	<ul className="px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">*/}
			{/*		<li className="nav-item nav_item" role="presentation">*/}
			{/*			<button*/}
			{/*				className={`nav-style g_mid ${activeTab === 'ongoing' && 'active'}`}*/}
			{/*				type="button"*/}
			{/*				onClick={() => handleTabChange('ongoing')}>*/}
			{/*				Ongoing*/}
			{/*			</button>*/}
			{/*		</li>*/}
			{/*		<li className="nav-item nav_item" style={{ marginLeft: '-40px' }} role="presentation">*/}
			{/*			<button*/}
			{/*				className={`nav-style g_mid ${activeTab === 'completed' && 'active'}`}*/}
			{/*				type="button"*/}
			{/*				onClick={() => handleTabChange('completed')}>*/}
			{/*				Completed*/}
			{/*			</button>*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<div id="myTabContent">*/}
			{/*		<div*/}
			{/*			className={`tab-pane fade ${activeTab === 'ongoing' && 'show active'}`}*/}
			{/*			id="home-tab-pane"*/}
			{/*			role="tabpanel">*/}
			{/*			{activeTab === 'ongoing' && (*/}
			{/*				<OnGoingCourse enrollmentCourses={userCourseData.inProgressCourses} />*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*		<div*/}
			{/*			className={`tab-pane fade ${activeTab === 'completed' && 'show active'}`}*/}
			{/*			id="profile-tab-pane"*/}
			{/*			role="tabpanel">*/}
			{/*			{activeTab === 'completed' && (*/}
			{/*				<CompletedCourse*/}
			{/*					enrollmentCourses={userCourseData.completedCourses}*/}
			{/*					hasPurchasedAnyCourse={*/}
			{/*						!!userCourseData.inProgressCourses.length ||*/}
			{/*						!!userCourseData.completedCourses.length*/}
			{/*					}*/}
			{/*					onGoToMyCourse={() => handleTabChange('ongoing')}*/}
			{/*				/>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			<CommonTabView
				isUserSpecificContent={true}
				selectedTabType={selectedTabType || SelectedTabType.ALL}
				showSearch={false}
			/>
		</section>
	);
};

export default MyCourse;
