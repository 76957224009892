import React, { useMemo, useState } from 'react';
import PersonalDetail from './PersonalDetail';
import ChangePassword from './ChangePassword';
import './MyProfile.css';
import { useUserContext } from 'context/UserContext';
import { UserSigninType } from 'types/globalTypes';
import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { OrderObjectType } from 'generated/types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useUserAddressContext } from 'context/UserAddressContext';
import { useCourseContext } from 'context/CourseContext';
import { BASE_PAGE_URL } from 'pages/routing/routePages';
import toast from 'react-hot-toast';


interface OrderListObjectType {
	records: OrderObjectType[];
	pagination: OrderPaginationType;
}

interface OrderPaginationType {
	totalRecords: number;
	currentPage: number;
}

interface GetStudentOrderHistoryQuery {
	getStudentOrderHistory: OrderListObjectType;
}
const GET_STUDENT_ORDER_HISTORY = gql`
	query PaymentHistory_Query(
		$where: StudentProfileResolver_GetStudentOrderHistory_FilterInputType
		$paginate: PaginatorArgs!
	) {
		getStudentOrderHistory(where: $where, paginate: $paginate) {
			records {
				id
				userId
				subTotal
				total
				tax
				gstNo
				status
				transactionId
				paymentGatewayId
				paymentGatewayMode
				transactionStatus
				transactionDateTime
				totalDiscount
				type
				createdAt
				deletedAt
				platform
				paymentLink
				invoiceStatus
				orderDetails {
					orderId
					price
					course {
						name
					}
				}
				address_id
				companyName
				registerAddress
				userAddress {
					userId
					fullname
				}
				user {
					id
					username
				}
			}
			pagination {
				totalRecords
				currentPage
			}
		}
	}
`;

const MYProfile = () => {
	const navigate = useNavigate();
	const { logOutUser } = useUserContext();
	const { setSelectedStateId, setSelectedCityId, setSelectedCountryId, setStates, setCities } =
		useUserAddressContext();
	const { setUserCourses } = useCourseContext();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [whereClause, setWhereClause] = useState({});
	const [apiSource, setApiSource] = useState<'paymentDetails' | 'orderHistory' | null>(null);
	const [paymentRecords, setPaymentRecords] = useState<OrderObjectType[]>([]);

	const {
		loading: loadingOrderHistory,
		error: errorOrderHistory,
		data: dataOrderHistory,
		refetch: refetchOrderHistory,
	} = useQuery<GetStudentOrderHistoryQuery>(GET_STUDENT_ORDER_HISTORY, {
		variables: {
			where: whereClause,
			paginate: { page: currentPage - 1, per_page: 6 },
		},
		fetchPolicy: 'network-only',
		onError: (graphQLError) => {
			console.error('GraphQL error:', graphQLError);
		},
		onCompleted: (data) => {
			setApiSource('orderHistory');
			const totalRecords = data.getStudentOrderHistory.pagination.totalRecords;
			setPaymentRecords(data.getStudentOrderHistory.records);
			const perPage = 6;
			setTotalPages(Math.ceil(totalRecords / perPage));
		},
	});
	const [activeTab, setActiveTab] = useState('personal');
	const { user } = useUserContext();
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Your sign-up method is Google. Password change is not available.
		</Tooltip>
	);
	const userSignInTypeEmail = useMemo(() => user?.signinType === UserSigninType.EMAIL, [user]);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	if (errorOrderHistory) {
		console.error('GraphQL error:', errorOrderHistory);
		logOutUser();
		setSelectedCityId('');
		setSelectedCountryId('');
		setSelectedStateId('');
		setUserCourses([]);
		setStates([]);
		setCities([]);
		navigate(BASE_PAGE_URL);
		toast.success('Logout successfully');
	}

	return (
		<section className="profile-main-layout" style={{ backgroundColor: 'white' }}>
			<div className="container-fluid px-md-5 py-4">
				<ul className="d-flex px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`profile-tabs me-3 ${activeTab === 'personal' ? 'active' : ''}`}
							type="button"
							onClick={() => handleTabChange('personal')}>
							Personal Details
						</button>
					</li>
					<li className="nav-item nav_dash_item" role="presentation">
						{userSignInTypeEmail ? (
							<button
								className={`profile-tabs me-3 ${activeTab === 'password' ? 'active' : ''}`}
								type="button"
								onClick={() => handleTabChange('password')}>
								Change Password
							</button>
						) : (
							<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 250 }}
								overlay={renderTooltip}>
								<span className="d-inline-block">
									<button
										className={`profile-tabs g_mid ${activeTab === 'password' ? 'active' : ''}`}
										type="button"
										disabled
										style={{ pointerEvents: 'none' }}>
										Change Password
									</button>
								</span>
							</OverlayTrigger>
						)}
					</li>
				</ul>
				<div id="myTabContent">
					<div
						className={`tab-pane fade ${activeTab === 'personal' ? 'show active' : ''}`}
						id="home-tab-pane"
						role="tabpanel">
						{activeTab === 'personal' && <PersonalDetail />}
					</div>
					<div
						className={`tab-pane fade ${activeTab === 'password' ? 'show active' : ''}`}
						id="profile-tab-pane"
						role="tabpanel">
						{activeTab === 'password' && <ChangePassword />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default MYProfile;
